<template>
    <v-container fluid>
        <v-form @submit.prevent="saveFitnessMain">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'fitness_main'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-row>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="image" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <make-photo v-model="image"/>
                                            <div v-if="!valid" class="mt-2" style="color: red">
                                                {{ errors[0] ? errors[0] : $t('photo_not_added') }}
                                            </div>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="9">
                                <v-row>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="fitness_main" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="fitness_main" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-view-list"
                                                          :label="$t('fitness_main_name')" color="primary"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="type" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="type" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-view-list"
                                                          :label="$t('type')" color="primary"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>


                                </v-row>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="px-4 py-6">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import MakePhoto from '../components/MakePhoto.vue'
    import {mapGetters} from "vuex"

    export default {
        name: 'FitnessMainForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            MakePhoto
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                tab: 0,
                language: null,
                loading: false,
                fitness_main: null,
                type: null,
                image: null,
                exist_translations: {},
                all_translations: true,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        methods: {
            async checkCreate() {
                if (this.$route.name === 'fitness_main.create') {
                    this.heading = this.$t('fitness_main_creation')
                } else {
                    this.heading = this.$t('fitness_main_editing')
                    if (this.$route.params.id) {
                        await this.getFitnessMain()
                    }
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            async getFitnessMain(id) {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/fitness_main/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.image = res.body.data.image
                        this.fitness_main = res.body.data.name
                        this.type = res.body.data.type
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_fitness_main'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveFitnessMain() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }

                if (this.image) {
                    if (this.image.length > 250) {
                        var mimeType = this.getMimeType(this.image)
                        var blob = this.dataURL64toBlob(this.image)
                        if (mimeType && blob) {
                            formData.append('image', blob, mimeType)
                        }
                    } else {
                        formData.append('image', this.image)
                    }
                }
                if (this.fitness_main) {
                    formData.append('fitness_main', this.fitness_main)
                }
                if (this.type) {
                    formData.append('type', this.type)
                }



                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`admin/fitness_main/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('fitness_main_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.forceRerender()
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('fitness_main_has_not_been_updated'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/fitness_main', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('fitness_main_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'fitness_main.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                                this.forceRerender()
                            }
                            //this.$router.push({ name: 'fitness_main' })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('fitness_main_has_not_been_added'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
